<template>
  <div>
    <Loading v-if="loading" />
    <v-data-table
      v-if="$store.getters['post/HasPageLoaded'](postPage)"
      :headers="headers"
      :items="$store.getters['post/GetPosts'](postPage).data"
      :items-per-page="5"
      :hide-default-footer="true"
      :disable-filtering="true"
      :disable-sort="true"
      :disable-pagination="true"
    >
      <template #[`item.timestamp`]="{item}">
        {{ unixToFormattedTime(item.timestamp) }}
      </template>
      <template #[`item.edit`]="{item}">
        <v-btn
          v-if="item.authorId == $store.getters['user/GetLocalUserId'] || $store.getters['user/IsAuthorized']('edit_all_articles')"
          color="warning"
          dark
          @click="$emit('openArticleEditor', item)"
        >
          Edit
        </v-btn>
        <v-btn
          v-if="item.authorId == $store.getters['user/GetLocalUserId'] || $store.getters['user/IsAuthorized']('edit_all_articles')"
          color="error"
          dark
          class="ml-12"
          @click="deleteArticle(item)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="postPage"
        :disabled="!$store.getters['post/HasPageLoaded'](postPage)"
        :total-visible="0"
        :length="$store.getters['post/GetCount']()"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { unixToFormattedTime } from '@core/utils/'
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagon,
  mdiOpenInNew,
} from '@mdi/js'
import axiosClient from '@/plugins/axios'
import Loading from '@/views/components/Loading.vue'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      headers: [
        { text: 'Created on', value: 'timestamp' },
        { text: 'Title', value: 'title' },
        { text: '', value: 'edit' },
        { text: '', value: 'delete' },
      ],
      postPage: 1,
      pagination: {
        sortBy: 'reportee',
        rowsPerPage: 5,
        page: 1,
        totalItems: 0,
      },
      loading: false,
    }
  },
  watch: {
    postPage(val) {
      if (!this.$store.getters['post/HasPageLoaded'](val)) this.$store.dispatch('post/loadPosts', { page: val })
    },
  },
  methods: {
    deleteArticle(article) {
      this.loading = true
      axiosClient({
        url: `${this.$API}/article/${article.id}`,
        method: 'DELETE',
      })
        .then(result => {
          this.loading = false
          this.$store.dispatch('post/reloadPages')
          this.$root.vtoast.show('The post was deleted successfully!')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.$root.vtoast.show('An error occurred while deleting the post!')
        })
    },
  },
  setup() {
    return {
      unixToFormattedTime,
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiAlertOctagon,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style>
.post-editor {
  min-height: 800px;
}
</style>
