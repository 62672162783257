<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        Editor
      </v-tab>
      <v-tab>
        All articles
      </v-tab>
      <v-tab-item class="post-editor"><PostEditor :existingArticle="existingArticle" @resetExisting="existingArticle = null"/></v-tab-item>
      <v-tab-item><AllPosts @openArticleEditor="openArticleEditor"/></v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import PostEditor from '@/views/components/admin/PostEditor.vue'
import AllPosts from '@/views/components/admin/AllPosts.vue'

import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiBrightness1,
  mdiTrophy,
  mdiTwitch,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlertOctagon,
  mdiOpenInNew,
} from '@mdi/js'

export default {
  components: {
    PostEditor,
    AllPosts,
  },
  data() {
    return {
      tab: 0,
      existingArticle: null,
    }
  },
  methods: {
    openArticleEditor(article) {
      this.existingArticle = article
      this.tab = 0
    },
  },
  setup() {
    return {
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiBrightness1,
        mdiTrophy,
        mdiTwitch,
        mdiChevronUp,
        mdiChevronDown,
        mdiAlertOctagon,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style>
.post-editor {
  min-height: 800px;
}
</style>