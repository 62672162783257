<template>
  <div>
    <Loading v-if="loading" />
    <vue-editor
      v-model="content"
      use-custom-image-handler
      @image-added="handleImageAdded"
    ></vue-editor>
    <v-form
      ref="form"
      v-model="valid"
      class="post-data"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="title"
            :rules="titleRules"
            :counter="50"
            required
            label="Title"
          ></v-text-field>
          <v-select
            v-model="country"
            :items="countries"
            item-text="name"
            item-value="abbr"
            label="Country"
          ></v-select>
          <v-file-input
            v-model="featuredImage"
            :rules="featuredImageRules"
            accept="image/png, image/jpeg, image/bmp"
            required
            label="Featured image"
          ></v-file-input>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-textarea
            v-model="description"
            :rules="descriptionRules"
            :counter="200"
            :rows="4"
            required
            no-resize
            label="Description"
          ></v-textarea>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center"
        >
          <v-btn
            class="mr-4 success"
            :disabled="!valid || content == '' || featuredImage == null || country == null"
            @click="submit"
          >
            Save post
          </v-btn>
          <v-btn
            v-if="existingArticle"
            class="mr-4 error"
            @click="$emit('resetExisting')"
          >
            Close article
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import axiosClient from '@/plugins/axios'
import Loading from '@/views/components/Loading.vue'

export default {
  components: {
    VueEditor,
    Loading,
  },
  props: ['existingArticle'],
  data() {
    return {
      title: '',
      description: '',
      content: '',
      featuredImage: null,
      titleRules: [v => !!v || 'Title is required', v => v.length <= 50 || 'Title must be less than 50 characters'],
      descriptionRules: [
        v => !!v || 'Description is required',
        v => v.length <= 200 || 'Description must be less than 200 characters',
      ],
      featuredImageRules: [
        v => !!v || 'Featured image is required',
        v => v.size < 10000000 || 'Featured image size should be less than 10 MB!',
      ],
      valid: false,
      loading: false,
      country: 'eu',
      countries: [
        { name: 'Estonia', abbr: 'ee' },
        { name: 'Latvia', abbr: 'lv' },
        { name: 'Lithuania', abbr: 'lt' },
        { name: 'All', abbr: 'eu' },
      ],
    }
  },
  watch: {
    async existingArticle(val) {
      if (val == null) {
        this.title = ''
        this.description = ''
        this.content = ''
        this.country = 'eu'
        this.featuredImage = null
      } else {
        this.loading = true
        this.title = val.title
        this.description = val.description
        this.content = val.content
        this.country = val.country
        const config = { responseType: 'blob' }
        axiosClient.get(val.featuredImage, config).then(response => {
          this.featuredImage = new File([response.data], 'image.jpg', { type: response.data.type })
          this.loading = false
        })
      }
    },
  },
  methods: {
    async submit() {
      this.loading = true
      axiosClient({
        url: `${this.$API}/article/`,
        method: 'POST',
        data: {
          id: this.existingArticle?.id,
          title: this.title,
          description: this.description,
          country: this.country,
          content: this.content,
          image: await this.toBase64(this.featuredImage),
          imageExtension: this.featuredImage.type,
        },
      })
        .then(result => {
          if (this.existingArticle) {
            this.$emit('resetExisting')
          }
          this.$store.dispatch('post/reloadPages')
          this.loading = false
          this.title = ''
          this.description = ''
          this.content = ''
          this.featuredImage = null
          this.$root.vtoast.show('The post was saved successfully!')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.$root.vtoast.show('An error occurred while creating the post!')
        })
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.loading = true
      axiosClient({
        url: `${this.$API}/article/image/`,
        method: 'POST',
        data: {
          data: await this.toBase64(file),
          extension: file.type,
        },
      })
        .then(result => {
          this.loading = false
          const url = result.data // Get url from response
          console.log(url)
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch(err => {
          this.loading = false
          this.$root.vtoast.show('Image upload failed!')
          console.log(err)
        })
    },
  },
}
</script>

<style>
.post-data {
  margin: 16px;
}

.ql-editor {
  height: 650px !important;
}

.ql-toolbar.ql-snow {
  border: 0 !important;
  color: rgba(231, 227, 252, 0.76);
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: rgba(231, 227, 252, 0.76) !important;
}

.quillWrapper .ql-snow .ql-stroke {
  stroke: rgba(231, 227, 252, 0.76) !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker-label {
  color: white !important;
}

.ql-container.ql-snow {
  border: 0 !important;
}

.loading {
  position: absolute;
  top: 40%;
  left: 47%;
  z-index: 10;
  visibility: visible;
}
</style>
